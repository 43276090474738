<template>
  <div class="companyType">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Company Types </CBreadcrumbItem>
    </teleport>

    <CCard class="settingCompanyInfo">
      <CCardBody>
        <CContainer class="py-3">
          <CAccordion :active-item-key="1">
            <CRow>
              <CCol sm="12" lg="6" class="listTypes">
                <AccordionItem
                  :pc-label="'Company Types'"
                  :mobile-label="'Company Types'"
                  :item-key="1"
                >
                  <ListItem :types="companyTypes" @change="fetchData" />
                </AccordionItem>
              </CCol>
              <CCol sm="12" lg="6">
                <AccordionItem
                  :item-key="2"
                  :mobile-label="'New Company Type Name'"
                >
                  <CRow>
                    <CCol>
                      <div class="mb-3">
                        <CFormLabel>New Company Type Name</CFormLabel>
                        <CFormInput
                          v-model="form.name"
                          placeholder="Enter new company type name"
                          maxlength="255"
                          :invalid="!!validationErrors.name"
                          @blur="handleChangeField('name', $event)"
                        />
                        <CFormText
                          v-if="validationErrors.name"
                          :style="`color: ${validationErrors.name && 'red'}`"
                        >
                          {{ validationErrors.name }}
                        </CFormText>
                      </div>

                      <CRow
                        class="justify-content-sm-center justify-content-md-start mx-0 w-100"
                      >
                        <CCol lg="auto" md="12" xl="auto">
                          <CButton
                            color="primary"
                            class="w-100"
                            @click="addType"
                          >
                            Add Type
                          </CButton>
                        </CCol>
                        <CCol lg="auto" md="12" xl="auto">
                          <CButton
                            variant="ghost"
                            class="w-100"
                            @click="clearForm"
                          >
                            Cancel
                          </CButton>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </AccordionItem>
              </CCol>
            </CRow>
          </CAccordion>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
