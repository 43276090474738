<template>
  <CModal :visible="visible" size="lg" style="text-align: center" @close="cancel">
    <CModalBody style="text-align: center" class="m-3">
      <h4>Are you sure you want to delete {{ modelValue.name }} type?</h4>
    </CModalBody>
    <CModalFooter>
      <CRow class="justify-content-center w-100">

        <CCol xs="6" class="text-end" >
          <CButton class="m-2 w-75" color="primary" variant="outline" @click="cancel">
            No, don't delete
          </CButton>
        </CCol>

        <CCol xs="6">
          <CButton class="m-2 w-75" color="primary" @click="confirmed">
            Yes, delete
          </CButton>
        </CCol>

      </CRow>
    </CModalFooter>
  </CModal>
</template>

<script>
export default {
  name: 'DeleteItem',
  props: {
    modelValue: { type: Object, default: () => {} },
  },
  emits: ['delete', 'update:modelValue'],
  data() {
    return {}
  },
  computed: {
    visible() {
      return this.modelValue.id
    },
  },
  methods: {
    confirmed() {
      this.$emit('delete', this.modelValue.id)
      this.$emit('update:modelValue', {})
    },
    cancel() {
      this.$emit('update:modelValue', {})
    },
  },
}
</script>

<style scoped></style>
